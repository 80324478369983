<template>
  <div class="contentbody">
    <b-loading is-full-page :active.sync="isLoading" :can-cancel="false"></b-loading>
    <div class="contentleft" v-if="w.left" v-bind:class="{ contentleftbig: w.right }">
      <div class="contentleft-inner">
        <div class="contentleft-header">
          <div class="mr5" @click="CreateDoc()">
            <b-button icon-left="plus" type="is-info">New</b-button>
          </div>
          <div class="taskSearchSel">
            <b-select v-model="ActiveGoalId" icon="bullseye-arrow" @input="setActiveGoal();getTeams(0);" expanded>
              <option v-for="(g, gi) in GoalList" :key="gi" :value="g._id">{{g.goaltitle}}</option>
            </b-select>
          </div>
          <div class="contentleft-header-searchbox">
            <b-input placeholder="Search" type="search" icon="search" v-model="searchField" @input="doSearch()" ></b-input>
          </div>
        </div>

        <div class="contentleft-body scrolly scrollx">
          <div class="tsk lgreybg p11 m11" v-if="docsList.length === 0">No Docs created</div>
          
          <div class="tsk p0 m7 isPointer isFlex isFlexCol tskshadow dcard" v-for="(n, index) in docsList" :key="index">
            <div class="txtc mt11 mb11" @click="openDocModal(n, index)">
              <font-awesome-icon :icon="['fal', 'chart-network']" size="2x"></font-awesome-icon>
            </div>
            <div class="isFlexGrow" @click="openDocModal(n, index)">
              <div class="txtc txtbold mb3 mt5">{{ n.title }}</div>
              <div class="txtsm txtc"><span v-if="n.goaltitle !== ''" :style="`color: ${n.goalcolor}`">{{n.goaltitle}}</span><span v-else>Not associated with a goal</span></div>
              <div class="txtc txtsm mb3 mt5">{{ n.oneliner }}</div>
            </div>
            <div class="isFlex isFlexWrap isFlexJustify vlgreybg roundedbottom p5">
              <b-button class="mr11" size="is-small" type="is-light" icon-left="chart-network" @click="openDocModal(n, index)">Open Doc</b-button>
              <b-button size="is-small" type="is-light" icon-left="edit" @click="openDoc(n, index)">Settings</b-button>
            </div>
          </div>
        </div>

        <div class="p5 isFlex">
          <div class="isFlexGrow mr11">
            <b-pagination
              @change="getTeams($event)"
              :total="total"
              :current="current"
              :range-before="rangeBefore"
              :range-after="rangeAfter"
              :order="order"
              :size="size"
              :simple="isSimple"
              :rounded="isRounded"
              :per-page="perPage"
              :icon-prev="prevIcon"
              :icon-next="nextIcon"
              aria-next-label="Next page"
              aria-previous-label="Previous page"
              aria-page-label="Page"
              aria-current-label="Current page"
            >
            </b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div class="contentright" v-if="w.right">
      <div class="contentright-inner">
        <div class="contentright-nav">
          <div class="iconbutton mv5" @click="closeRight()">
            <font-awesome-icon :icon="['fal', 'times']" size="lg" ></font-awesome-icon>
          </div>
          <div v-if="ActiveDocs.canEdit">
            <div v-if="!isEdit">
              <div class="iconbutton mv5" @click="isEdit=true">
                <font-awesome-icon :icon="['fal', 'edit']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div v-if="isEdit">
              <div class="iconbutton mv5" @click="isEdit=false">
                <font-awesome-icon :icon="['fal', 'eye']" size="lg" ></font-awesome-icon>
              </div>
            </div>
            <div style="height: 20px;"></div>
            <div class="iconbutton mv9 bluetxt11" @click="docSave()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'save']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv9 bluetxt11" @click="CopyDoc()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'copy']" size="lg" ></font-awesome-icon>
            </div>
            <div class="iconbutton mv9 red" @click="docDelete()" v-if="canEdit && isEdit">
              <font-awesome-icon :icon="['fal', 'trash']" size="lg" ></font-awesome-icon>
            </div>
          </div>
        </div>
        <div class="contentright-body ">
          <div class="contentright-body-body scrolly">

            <div class="p11">
              <!-- attach to goal: start -->
              <div class="pt9 mb5" v-if="isEdit">
                <div class="field" v-if="isadmin"><b-switch v-model="ActiveDocs.demo">Demo</b-switch></div>
                <div class="txtbold mb3">Associated with goal...</div>
                  <b-select placeholder="Select Goal" expanded v-model="ActiveDocs.goalid" @input="changeGoal">
                <option v-for="gc in GoalList" :value="gc._id" :key="gc._id">{{ gc.goaltitle }}</option>
                </b-select>
              </div>
              <div v-if="!isEdit" class="txtsm mb5">Goal: <span v-if="ActiveDocs.goaltitle !== ''">{{ActiveDocs.goaltitle}}</span><span v-else>Not associated with a goal</span></div>

              <!-- Title: start -->
              <div class="mt5 mb5" v-if="isEdit">
                <div class="txtbold mb3">Give your mind map a name...</div>
                <b-input v-model="ActiveDocs.title" placeholder="Please enter a title"></b-input>
              </div>
              <div v-if="!isEdit" class="txth5 txtbold mb5">{{ActiveDocs.title}}</div>
              <!-- Oneliner: start -->
              <div class="mt5 mb5" v-if="isEdit">
                <div class="txtbold mb3">Short description that will appear in the listings.</div>
                <b-input v-model="ActiveDocs.oneliner" placeholder="An interesting tale"></b-input>
              </div>
              <div v-if="!isEdit" class="mb5">{{ActiveDocs.oneliner}}</div>
              <!-- description -->
              <div class="mt5 mb11" v-if="isEdit">
                <div class="txtbold mb3">Description</div>
                <b-input v-model="ActiveDocs.description" type="textarea" rows="3"></b-input>
              </div>
              <div v-if="!isEdit && ActiveDocs.description !== ''" class="mb5">{{ActiveDocs.description}}</div>

              <div class="mt11">
                <div class="txtbold">Share address</div>
                <div>This document is only available to a person who has this url, they will only have read access.</div>
                <!-- <div><a :href="share" target="_blank">Open</a></div> -->
                <div>
                  <b-input placeholder="Small" v-model="share" size="is-small"></b-input>
                </div>
              </div>
              
            </div>

          </div>
          <!-- <div class="p5 vlgreybg isFlex isFlexSpace" v-if="isEdit">
            <b-button @click="docDelete" icon-left="trash" type="is-light" size="is-small">Delete</b-button>
            <b-button @click="docSave" icon-left="save" type="is-light" size="is-small">Save</b-button>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import Vue from "vue";
import { EventBus } from '../event-bus.js';
import { Utils } from '../mixins/utils.js';
import { Schemas } from "@/schemas/all.js";
import debounce from "lodash/debounce";
import array from "lodash/array";
import { windowSplit2 } from "../mixins/windowSplit.js";
import draggable from "vuedraggable";
import ModalDocs from '../modals/ModalDocs.vue';

Vue.component("pillbox", {
  template: "pillbox-template",
  props: {
    label: String,
    // value: undefined,
    width: { type: String, default: '100px' },
  },
  data: function () {
    return {
      lb: this.label,
      // vl: this.value,
      st: `width: ${this.width};`,
    }
  },
  template: `<div class="mb5 isFlex isFlexAlign"><div class="roundedleft lgreybg p5 pl11" style="width: 100px;">{{lb}}</div><div class="p5 isFlexGrow vlgreybg roundedright"><span><slot></slot></span></div></div>`});

export default {
  mixins: [Utils, windowSplit2, Schemas],
  components: {
    draggable
  },
  name: 'Teams',
  data() {
    return {
      editor: null,
      docsList: [],
      searchField: '',
      ActiveDocs: {},
      ActiveDocsId: -1,
      ActiveId: -1,
      ActiveRow: {},
      isEdit: false,
      canEdit: false,
      showEditor: false,
      canEdit: false,
      isNew: false,
      pageW: 0,

      isLoading: true,
      GoalList: [],
      sub: localStorage.getItem("sub"),

      // pagination: start
      searchField: "",
      perPage: 20,
      prodCount: 0,
      total: 0,
      current: 1,
      rangeBefore: 3,
      rangeAfter: 1,
      order: "",
      size: "",
      isSimple: false,
      isRounded: false,
      prevIcon: "chevron-left",
      nextIcon: "chevron-right",
      // pagination: end

      share: '',

      isadmin: localStorage.getItem("isadmin"),
    }
  },
  created() {
    this.getGoalSelect();
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.getTeams(0);
    // this.getGoals();
  },
  methods: {
    
    async getGoals() {
      this.GoalList = [];

      const glin = await this.DataGet("/goals/select");
      const gl = glin;

      for (const g of gl) {
        // set style
        if (g.goalcolor === "") {
          g.goalcolor = "#CCCCCC";
        }
        const nr = {
          _id: g._id,
          goaltitle: g.goaltitle,
          goalcolor: g.goalcolor
        }
        this.GoalList.push(nr);
      }
      if(!this.isNew) {
        this.GoalList.push({
        _id: "",
        goaltitle: "Not associated with a goal",
        goalcolor: "#CCC"
      });
      }
      this.loading = false;
    },

    doSearch: debounce(function() {
      this.current = 1;
      this.total = 0;
      this.getTeams(0);
    }, 2000),

    async getTeams(type) {
      this.isLoading = true;

      const sendData = {
        searchField: this.searchField,
        goalid: this.ActiveGoalId,
        perPage: this.perPage,
        skip: 0
      };
      this.current = type;
      if (type === 0) {
        this.current = 1;
        let getcount = await this.DataSend('post','/teams/listcount', sendData, 'rows', false);
        let totalRows = getcount;
        if (totalRows > this.perPage) {
          totalRows = totalRows / this.perPage;
          totalRows = Math.ceil(totalRows);
          this.total = totalRows * this.perPage;
        } else {
          this.total = totalRows;
        }
      }

      if (type > 1) {
        sendData.skip = this.perPage * (type - 1);
      }

      const gnotify = await this.DataSend('post', '/teams', sendData, 'rows', false);
      
      this.docsList = gnotify;
      this.isLoading = false;
    },

    async openDoc(row, index, isEdit=false) {
      this.ActiveDocsId = index;
      this.editor = null;
      this.showEditor = false;
      this.isLoading = true;
      this.isNew = false;
      this.isEdit = isEdit;
      const gc = await this.DataGet(`/teams/${row._id}`);
      this.isNew = false;
      this.ActiveDocs = gc;
      this.canEdit = gc.canEdit;
      this.share = `${window.location.origin}/share/teams/${row._id}`;
      if (gc.canEdit) {
        this.isEdit = true;
      }
      this.openRight();
      this.isLoading = false;
    },

    changeGoal(data){
      if (data !== '') {
        const gindex = array.findLastIndex(this.GoalList, { '_id': data });
        this.ActiveDocs.goalid = data;
        this.ActiveDocs.goaltitle = this.GoalList[gindex].goaltitle;
        this.ActiveDocs.goalcolor = this.GoalList[gindex].goalcolor;
      } else {
        this.ActiveDocs.goalid = '';
        this.ActiveDocs.goaltitle = '';
        this.ActiveDocs.goalcolor = '';
      }
    },

    async CreateDoc() {
      const newdocs = await this.DataGet('/teamsnew');
      this.docsList.unshift(newdocs);
      this.openDoc(newdocs, 0, true);
    },

    async CopyDoc() {
      this.$buefy.dialog.prompt({
        message: `Please enter a new name for this mind map`,
        inputAttrs: {
          placeholder: "...",
          maxlength: 200
        },
        trapFocus: true,
        onConfirm: async value => {
          const newmm = {...this.ActiveDocs};
          delete newmm._id;
          delete newmm.__v;
          delete newmm.createdAt;
          delete newmm.updatedAt;
          newmm.title = value;
          const copiedmm = await this.DataSend('put', '/teamscopy', newmm, 'rows');
          this.docsList.unshift(copiedmm);
          this.openDoc(copiedmm, 0, true);
        }
      });
    },

    async docSave() {
      const data = {
        title: this.ActiveDocs.title,
        type: this.ActiveDocs.type,
        oneliner: this.ActiveDocs.oneliner,
        goalid: this.ActiveDocs.goalid,
        goaltitle: this.ActiveDocs.goaltitle,
        goalcolor: this.ActiveDocs.goalcolor,
      }
      const dosave = await this.DataSend('post', '/teams/updatefew', this.ActiveDocs, 'rows', true);
      this.docsList[this.ActiveDocsId].title = this.ActiveDocs.title;
      this.docsList[this.ActiveDocsId].type = this.ActiveDocs.type;
      this.docsList[this.ActiveDocsId].oneliner = this.ActiveDocs.oneliner;
      this.docsList[this.ActiveDocsId].goalid = this.ActiveDocs.goalid;
      this.docsList[this.ActiveDocsId].goaltitle = this.ActiveDocs.goaltitle;
    },

    async docDelete() {
      this.$buefy.dialog.confirm({
        title: "",
        message:
        "Are you sure you want to <b>delete</b> this mind map? This cannot be undone.",
        confirmText: "Delete mind map",
        type: "is-danger",
        hasIcon: true,
        icon: 'exclamation',
        onConfirm: async () => {
          this.closeRight();
          const deldocs = await this.DataDelete(`/teams/${this.ActiveDocs._id}`, "row");
          this.docsList.splice(this.ActiveDocsId, 1);
        }
      });
    },

    openDocModal(row, index) {
      this.ActiveRow = row;
      this.ActiveId = index;
      this.modal = this.$buefy.modal.open({
        parent: this,
        component: ModalDocs,
        hasModalCard: true,
        fullScreen: true,
        canCancel: false,
        props: {
          data: this.ActiveRow,
        },
        events: {
          dataFromModal: value => {}
        }
      });
    },

    


  },
  computed: {
    
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    // EventBus.$off('task_open');
    // EventBus.$off('task_added');
  }
}
</script>

<style>
.ProseMirror {
  /* border: 1px solid #CCC; */
  min-height: 300px;
  padding: 5px;
}
.ProfileDesc > p{
  margin-bottom: 10px;
}
</style>
