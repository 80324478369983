<template>
  <div class="modal-card" style="background-color: #F6F6F6 !important;">
    <!-- <header class="modal-card-head" style="padding: 10px; background-color: #F6F6F6 !important;">
      <div class="isFlexGrow"><p class="modal-card-title">{{DOC.title}}</p></div>
      <b-button @click="SaveDocs" icon-left="save" v-if="canEdit">Save</b-button>
      <b-button @click="$parent.close()" icon-left="times" class="ml11">Close</b-button>
    </header> -->
    <section class="modal-card-body midgreybg p11" style="position: relative;" id="modalbody">
      <b-loading :active.sync="isLoading" :can-cancel="false" :is-full-page="false"></b-loading>
      <div style="position: absolute; top: 20px; right: 20px; width: 200px;" class="bluebg1 shadow">
        <div>Info</div>
        <div></div>
      </div>
      <div v-for="p in DOC.pages" :key="p.id">
        <!-- page controls: start -->
        <div>
          <div class="isFlex">
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'edit']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'border-top']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'border-bottom']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'clone']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'trash']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'arrow-alt-up']" size="lg"></font-awesome-icon></div>
            <div class="iconbutton white mr9" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'arrow-alt-down']" size="lg"></font-awesome-icon></div>
            <div class="white">{{p.title}}</div>
          </div>
        </div>
        <!-- page controls: end -->
        <div class="mb11 shadow" :style="`width: ${DOC.width}px; height: ${DOC.height}px; background-color: ${p.bgcol}`"></div>
      </div>

      <div v-for="b in DOC.blocks" :key="b.id" class="lgreyborder">
        <!-- row controls: start -->
        <div class="isFlex lgreybg">
          <div>Row</div>
          <div class="iconbutton" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
        </div>
        <!-- row controls: end -->

        <!-- col: start -->
        <div v-for="c in b.cols" :key="c.id" class="blueborder1">
          <!-- col controls: start -->
          <div class="isFlex bluebg1">
            <div>Col</div>
            <div class="iconbutton" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
          </div>
          <!-- col controls: end -->
          <!-- blocks: end -->
          <div v-for="i in c.blocks" :key="i.id" class="isFlexGrow">
            <div>
              <!-- block controls: start -->
              <div class="isFlex bluebg1">
                <div>Block</div>
                <div class="iconbutton" @click="changeViewEdit()"><font-awesome-icon :icon="['fal', 'edit']" size="sm"></font-awesome-icon></div>
              </div>
              <!-- block controls: end -->
              <div v-if="i.type === 'text'">
                <contenteditable tag="div" :contenteditable="isEdit" v-model="i.body" :noNL="false" :noHTML="false"/>
              </div>
              <contenteditable tag="div" class="midgrey txtitalic txtmd" :contenteditable="isEdit" v-model="i.instructions" :noNL="false" :noHTML="true"/>
            </div>
          </div>
          <!-- blocks: end -->
        </div>
        <!-- col: end -->
        
      </div>
    </section>
    <footer class="modal-card-foot isFlex" style="padding: 10px; background-color: #F6F6F6 !important; height: 42px;"> 
      <div class="isFlexGrow">
        <b-button @click="applyformat('bold')">B</b-button>
        <b-button @click="applyformat('italic')">I</b-button>
        <b-button @click="applyformat('removeFormat')" icon-left="remove-format"></b-button>
      </div>
      <div v-if="canEdit && showButtons">
        <b-button @click="meLineDel" icon-left="trash" v-if="canEdit"></b-button>
      </div>
      <div>
        <b-button @click="SaveDocs" icon-left="save" v-if="canEdit">Save</b-button>
        <b-button @click="$parent.close()" icon-left="times" class="ml3">Close</b-button>
      </div>
    </footer>
  </div>
</template>

<script>
import { Utils } from "@/mixins/utils.js";
import { Schemas } from "@/schemas/all.js";
import debounce from "lodash/debounce";
import isEmpty from "lodash/isEmpty";
export default {
  name: "ModalMapForm",
  mixins: [Utils, Schemas],
  props: {
    data: Object,
  },
  data() {
    return {
      ModalWidth: `width: auto`,
			DOC: {},
			isEdit: true,
			canEdit: false,
      canArc: false,
      isLoading: true,
      showButtons: false,
      page: {
        id: '',
        title: 'Page 1',
        blocks: [],
        bgcol: '#FFF',
        padding: {
          top: '10px',
          right: '10px',
          bottom: '10px',
          left: '10px',
        },
        comments: []
      },
      contRow: {
        id: '',
        cols: []
      },
      contCol: {
        id: '',
        title: 'Page 1',
        blocks: []
      },
      contObj: {
        id: '',
        type: 'text',
        body: 'This is some example text',
        instructions: 'What you want to do is replace the above text with your own',
        link: '',
        alt: '',
        comments: [],
      }
    };
  },
  created() {
    const inh = document.body.clientHeight;
    
  },
  mounted() {
    this.loadDocs();
  },
  methods: {
		async loadDocs() {
      const inid = this.data._id;
      if (localStorage.getItem("sub") === null) {
        this.isShare = true;
        this.DOC = await this.DataGet(`/docsopen/${inid}`);
      } else {
        this.DOC = await this.DataGet(`/docs/${inid}`);
      }
      console.log('doc', this.DOC);

      if(this.DOC.pages.length === 0) {
        const pg = this.copyObj(this.page);
        pg.id = this.uuidSM('p','');
        this.DOC.pages.push(pg);
      }

      if(this.DOC.blocks.length === 0) { 
        const nr = this.copyObj(this.contRow);
        const nc = this.copyObj(this.contCol);
        const nobj = this.copyObj(this.contObj);
        nr.id = this.uuidSM('r','');
        nc.id = this.uuidSM('c','');
        nobj.id = this.uuidSM('b','');
        nc.blocks.push(nobj);
        nr.cols.push(nc);
        this.DOC.blocks.push(nr);
      }
      console.log('this.DOC.blocks', this.DOC.blocks);
      this.canEdit = this.DOC.canEdit;
      this.isLoading = false;
    },

    applyformat(tp) {
      if (tp === 'bold' || tp === 'italic') {
        document.execCommand(tp, false, null);
      }
      if (tp === 'H1' || tp === 'H2' || tp === 'H3' || tp === 'H4' || tp === 'H5') {
        document.execCommand('formatBlock', false, tp);
      }
      if (tp === 'removeFormat') {
        document.execCommand('removeFormat', false, null);
      }
      if (tp === 'insertParagraph') {
        document.execCommand('insertParagraph', false, null);
      }
    },

    doSave: debounce(function() {
      this.SaveDocs(false);
    }, 2000),

    async SaveDocs() {
      console.log('save', this.DOC);
      if(this.canEdit) {
        // this.DataSend('put', '/docs', this.DOC, '', false);
      }
      // this.$emit("dataUpdated", { type, data: updata.rows });
      // if (close) {
      //   this.$parent.close();
      // }
    },
    
  }
};
</script>

<style>
.blrow {
  position: relative;
  border: 1px solid #EFEFEF;
  display: flex;
}
#map2 {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.lt {
  font-size: 20px;
  left: 20px;
  top: 20px;
  width: 40px !important;
}
</style>
